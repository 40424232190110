/** @jsxImportSource theme-ui */

import { Link } from "gatsby";
import { createMyDate, createTime, formatTime, MyDate } from "../utils/datetimeutils";
import { createTaikaiPath } from "../utils/linkutils";

interface DataNode {
  taikaiId: string | null;
  namePrefix: string | null;
  name: string | null;
  nameSuffix: string | null;
  canceled: string | null;
  date: string | null;
  isFest: number | null;
  endDate: string | null;
  isEndDateFest: number | null;
  openTime: string | null;
  startTime: string | null;
  endTime: string | null;
  locationName: string | null;
  locationSubName: string | null;
}

type UpcomingListProps = {
  data: readonly { node: DataNode }[];
  isAnchorLink: boolean;
};

interface DataNodeWithObjects extends DataNode {
  dateObj?: MyDate;
  endDateObj?: MyDate;
  openTimeObj?: Date;
  startTimeObj?: Date;
  endTimeObj?: Date;
}

interface DataWithObjects {
  node: DataNodeWithObjects;
}

const UpcomingList: React.FC<UpcomingListProps> = (props) => {
  const MAX_DAYS_FROM_NOW = 31;

  const isAnchorLink = props.isAnchorLink;

  const now = new Date();

  var thres = new Date();
  thres.setDate(thres.getDate() + MAX_DAYS_FROM_NOW);

  const dataWithObjects: DataWithObjects[] = props.data.map(({ node }) => {
    const d: DataWithObjects = {
      node: node,
    };
    d.node.dateObj = createMyDate(node.date, node.isFest);
    d.node.endDateObj = createMyDate(node.endDate, node.isEndDateFest);
    return d;
  });
  const entries = dataWithObjects.filter(({ node }) => {
    // remove entries that do not have exact dates
    if (!node.dateObj || !node.dateObj.getDate()) {
      return false;
    }
    // remove canceled entries
    if (node.canceled) {
      return false;
    }

    const startDate = node.dateObj.getDate();
    const endDate = node.endDateObj && node.endDateObj.getDate() ? node.endDateObj.getDate() : node.dateObj.getDate();

    // remove entries which occurred in the past
    if (endDate == null || endDate.getTime() + 24 * 60 * 60 * 1000 < now.getTime()) {
      return false;
    }

    // remove entries which start after MAX_DAYS_FROM_NOW days
    if (startDate == null || startDate > thres) {
      return false;
    }

    return true;
  });

  entries.forEach(({ node }) => {
    node.openTimeObj = createTime(node.openTime);
    node.startTimeObj = createTime(node.startTime);
    node.endTimeObj = createTime(node.endTime);
  });

  entries.sort((a, b) => {
    var closeDateA = a.node.dateObj?.getCloseDate();
    var closeDateB = b.node.dateObj?.getCloseDate();
    if (closeDateA && closeDateB) {
      return closeDateA.getTime() - closeDateB.getTime();
    } else if (a) {
      return 1;
    } else if (b) {
      return -1;
    } else {
      return 0;
    }
  });

  if (entries.length === 0) {
    return <div sx={{ marginBottom: "2.0rem" }}>直近1ヶ月間の催しはありません。</div>;
  }

  return (
    <div>
      <p>
        悪天候やその他の理由で予定が変更や中止になることもあります。<br />お出かけの際は主催者の情報をお確かめ下さい。
      </p>
      <ul sx={root}>
        {entries.map(({ node }) => {
          const url = isAnchorLink ? `#${node.taikaiId}` : createTaikaiPath(node.taikaiId ?? "");
          const dateStr = createDateStr(node.dateObj, node.endDateObj);
          const timeStr = createTimeStr(node.openTimeObj, node.startTimeObj, node.endTimeObj);

          return (
            <li key={node.taikaiId}>
              <p>
                <Link to={url}>{node.canceled}{node.namePrefix}{node.name}{node.nameSuffix}</Link>
                <br />
                日時： {dateStr}
                {timeStr}
                <br />
                場所： {node.locationName}
                {node.locationSubName}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default UpcomingList;

const root = {
  border: "1px solid",
  backgroundColor: "#fffce8", /* bright tone yellow (#FFED3A), opacity 20% */

  "li": {
    listStyleType: "disc",
    marginTop: "0.5rem",
    marginLeft: "2.0rem",
    marginRight: "1.0rem",
  },
};

function createDateStr(dateObj?: MyDate, endDateObj?: MyDate) {
  if (dateObj && endDateObj) {
    return `${dateObj.formatInJapanese(true, true)}～${endDateObj.formatInJapanese(true, true)}`;
  } else if (dateObj) {
    return dateObj.formatInJapanese(true, true);
  } else {
    return "";
  }
}

function createTimeStr(openTimeObj?: Date, startTimeObj?: Date, endTimeObj?: Date) {
  if (startTimeObj) {
    if (endTimeObj && openTimeObj) {
      return `${formatTime(startTimeObj)}～${formatTime(endTimeObj)} (開場${formatTime(openTimeObj)})`;
    } else if (endTimeObj) {
      return `${formatTime(startTimeObj)}～${formatTime(endTimeObj)}`;
    } else if (openTimeObj) {
      return `開場${formatTime(openTimeObj)}, 開演${formatTime(startTimeObj)}`;
    } else {
      return `開演${formatTime(startTimeObj)}`;
    }
  } else {
    if (openTimeObj) {
      return `開場${formatTime(openTimeObj)}`;
    } else {
      return "";
    }
  }
}
