/** @jsxImportSource theme-ui */

import { Link } from "gatsby";
import { MouseEvent, useEffect, useState } from "react";
import { createTaikaiPath } from "../utils/linkutils";

const SESSION_STORAGE_TAIKAI_LIST_OPENED = "taikai_list_opened";

interface TaikaiLinkNode {
  taikaiId: string | null;
  namePrefix: string | null;
  nameOfficial: string | null;
  nameSuffix: string | null;
  myNameSuffixDefault: string | null;
}

type TaikaiLinksListProps = {
  taikaiList: readonly { node: TaikaiLinkNode }[];
};

const TaikaiLinksList: React.FC<TaikaiLinksListProps> = (props) => {
  const taikaiList = props.taikaiList;

  const [openedState, setOpenedState] = useState(false);

  useEffect(() => {
    const opened = sessionStorage.getItem(SESSION_STORAGE_TAIKAI_LIST_OPENED) === "true";
    setOpenedState(opened);
  });

  // solution from https://github.com/facebook/react/issues/15486
  const updateOpenState = (event: MouseEvent) => {
    event.preventDefault();

    const newState = !openedState;
    sessionStorage.setItem(SESSION_STORAGE_TAIKAI_LIST_OPENED, `${newState}`);
    setOpenedState(newState);
  };

  return (
    <details open={openedState} sx={details}>
      <summary onClick={updateOpenState} sx={summary}>催し一覧（タップして表示）</summary>
      <div sx={eventList}>
        おおよその日程順に並べています。
        <ul>
          {taikaiList.map(({ node }) => {
            const text = `${node.namePrefix || ""}${node.nameOfficial || ""}${node.nameSuffix || ""}${node.myNameSuffixDefault || ""}`;
            return (
              <li key={node.taikaiId}>
                <Link to={createTaikaiPath(node.taikaiId ?? "")}>{text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </details>
  );
};

export default TaikaiLinksList;

const details = {
  marginLeft: "1.0rem",
  marginBottom: "0.5rem",
};

const summary = {
  marginBottom: "1.0rem",
};

const eventList = {
  marginLeft: "1.0rem",

  "ul": {
    listStyleType: "disc",
    marginTop: "0.5rem",
  },

  "li": {
    marginLeft: "1.3rem",
    marginTop: "0.3rem",
  },
};
