/** @jsxImportSource theme-ui */

import { graphql, Link } from "gatsby";
import type { PageProps } from "gatsby";
import { BaseStyles } from "theme-ui";
import Global from "../components/global";
import { Header } from "../components/header";
import HistoryList from "../components/history-list";
import Main from "../components/main";
import Seo from "../components/seo";
import TaikaiLinksList from "../components/taikai-links-list";
import UpcomingList from "../components/upcoming-list";
import Wrapper from "../components/wrapper";

export const Head = () => <Seo />;

const Home = ({ data }: PageProps<Queries.HomeQuery>) => {
  const HISTORY_LIST_MAX_NUM = 15;

  const taikaiList = data.allGoogleMetaSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.nameOfficial && !node.outdated;
  });

  const entryList = data.allGoogleDataSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.name;
  });

  return (
    <>
      <Global />
      <Header />
      <Main>
        <Wrapper>
          <BaseStyles>
            <p>
              ようこそ！<br />このサイトでは、祭囃子・神楽・獅子舞が見学できる関東地方の「郷土芸能発表会」等の催しを調査しています。
            </p>

            <h2>近日中に開催される催し</h2>
            <UpcomingList data={entryList} isAnchorLink={false} />

            <h2>更新履歴</h2>
            <HistoryList data={data.allGoogleHistorySheet.edges} maxItems={HISTORY_LIST_MAX_NUM} isAnchorLink={false} isShishimai={false} />

            <h2>コンテンツ</h2>
            <TaikaiLinksList taikaiList={taikaiList} />
            <ul sx={contentsList}>
              <li>
                <Link to="/calendar/">Googleカレンダー</Link>
              </li>
              <li>
                <Link to="/about/">このサイトについて</Link>
              </li>
              <li sx={{ marginBottom: "2.0rem" }}>
                <Link to="/links/">リンク</Link>
              </li>
              <li>
                <Link to="/index2/">旧サイトのスタイルで表示（PC向け）</Link>
              </li>
              <li>
                <Link to="/shishimai/">獅子舞大会のページへ</Link>
              </li>
            </ul>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default Home;

const contentsList = {
  listStyleType: "disc",

  "li": {
    marginTop: "0.3rem",
    marginLeft: "2.0rem",
  },
};

export const query = graphql`
  query Home {
    allGoogleMetaSheet(sort: {fields: date, order: ASC}, filter: {nameOfficial: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          outdated
          namePrefix
          nameOfficial
          nameSuffix
          myNameSuffixDefault
          date
        }
      }
    }
    allGoogleDataSheet(filter: {name: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          namePrefix
          name
          nameSuffix
          myNameSuffix
          canceled
          date
          isFest
          endDate
          isEndDateFest
          openTime
          startTime
          endTime
          locationName
          locationSubName
          note
          sources
        }
      }
    }
    allGoogleHistorySheet {
      edges {
        node {
          updateDate
          isNew
          taikaiId
          title
          startDate
          endDate
        }
      }
    }
  }
`;
